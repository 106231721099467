import React from 'react';

export const NotFoundPage = () => {
  return (
    <>
      <main className="min-h-full w-screen bg-cover bg-center bg-top sm:bg-top bg-sahara">
        <div className="max-w-7xl mx-auto px-8 py-16 text-center sm:px-6 sm:py-24 lg:px-16 lg:py-96">
          <h2 className="mt-2 text-4xl font-extrabold text-black tracking-tight sm:text-5xl">
            OUPS ! La page n'a pas été trouvée !
          </h2>
          <button className="mt-6" type="button">
            <a
              href="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Retour à la page d'accueil
            </a>
          </button>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;